import { StateCreator, create } from "zustand";
import { persist } from "zustand/middleware";
import localforage from "localforage";
import { formatPrice } from "../components/paymentUtil";
import { Membership } from "../services/stripe";
import {
  Gr4vyCheckoutData,
  CheckoutSessionResponse,
} from "../typings/paymentsServiceTypes";

interface PatientGuidData {
  consultGuid: string;
  episodeGuid: string;
}

interface PersistedStore {
  isApptSelected: boolean;
  setIsApptSelected: (isApptSelected: boolean) => void;
  patientGuidData: PatientGuidData | null;
  setPatientGuidData: (patientGuidData: PatientGuidData) => void;
  programPrice: number;
  setProgramPrice: (programPrice: number) => void;
  selectedMembership: Membership;
  setSelectedMembership: (membership: Membership) => void;
  checkoutData: Gr4vyCheckoutData;
  setCheckoutData: (checkoutData: Gr4vyCheckoutData) => void;
}

const defaultCheckoutData = {
  token: "",
  buyerID: "",
  amount: 0,
  cartItems: [],
  currency: "",
  country: "",
  locale: "",
  shippingDetailsId: "",
  totalTax: 0,
  checkoutSession: {} as CheckoutSessionResponse,
} as Gr4vyCheckoutData;

const storage = {
  getItem: async (name: string) => {
    const value = await localforage.getItem(name);
    return value ? JSON.parse(value as string) : null;
  },
  setItem: async (name: string, value: unknown) => {
    await localforage.setItem(name, JSON.stringify(value));
  },
  removeItem: async (name: string) => {
    await localforage.removeItem(name);
  },
};

export const usePersistStore = create<PersistedStore>(
  persist(
    (set) => ({
      isApptSelected: false,
      setIsApptSelected: (isApptSelected) => set({ isApptSelected }),
      patientGuidData: null,
      setPatientGuidData: (patientGuidData) => set({ patientGuidData }),
      programPrice: 0,
      setProgramPrice: (programPrice) => set({ programPrice }),
      selectedMembership: "" as Membership,
      setSelectedMembership: (selectedMembership: Membership) =>
        set({ selectedMembership }),
      checkoutData: defaultCheckoutData,
      setCheckoutData: (checkoutData: Gr4vyCheckoutData) =>
        set({ checkoutData }),
    }),
    {
      name: "funnel-quiz-state",
      storage, // Use localforage for storage
    }
  ) as StateCreator<PersistedStore>
);

export const clearPersistedStore = async () => {
  try {
    await localforage.removeItem("funnel-quiz-state");
    usePersistStore.setState({
      isApptSelected: false,
      programPrice: 0,
      selectedMembership: "" as Membership,
      checkoutData: defaultCheckoutData,
      patientGuidData: null,
    });
  } catch (error) {
    console.error("Failed to clear persisted store:", error);
  }
};

export const setPersistedProgramPrice = (price: number) => {
  usePersistStore.getState().setProgramPrice(price);
};

export const getPersistedProgramPrice = () => {
  return usePersistStore.getState().programPrice;
};

export const getFormattedPersistedProgramPrice = (
  currency: string = "USD",
  locale: string = "en-US"
) => {
  const programPrice = getPersistedProgramPrice();
  return formatPrice(programPrice, true, currency, locale);
};
