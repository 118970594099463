import { Membership } from "../services/stripe";
import { MembershipPriceSB, MembershipPrice } from "../typings/enums";

export const getProgramPrice = (
  subscription: Membership,
  monthsForPrice: number,
  semaglutideBundle: boolean
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return semaglutideBundle
        ? MembershipPriceSB.programPrice
        : MembershipPrice.monthly;
    case Membership.NutritionProgram:
      return MembershipPrice.nutritionProgram;
    default: // MultiMonthSubscription
      return semaglutideBundle
        ? MembershipPriceSB.programPrice * monthsForPrice
        : MembershipPrice.oneTime * monthsForPrice;
  }
};
export const getPharmacyCoverage = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return MembershipPriceSB.pharmacyCoverage;
    default: // Membership.MultiMonthSubscription:
      return MembershipPriceSB.pharmacyCoverage * monthsForPrice;
  }
};
export const getDiscountPrice = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return 0;
    default: // Membership.MultiMonthSubscription:
      return MembershipPriceSB.discount * monthsForPrice;
  }
};

export const getTotalPriceBeforeTax = (
  subscription: Membership,
  monthsForPrice: number,
  semaglutideBundle: boolean
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return semaglutideBundle
        ? MembershipPriceSB.monthly
        : MembershipPrice.monthly;
    case Membership.NutritionProgram:
      return MembershipPrice.nutritionProgram;
    default: // MultiMonthSubscription
      return semaglutideBundle
        ? MembershipPriceSB.oneTime * monthsForPrice
        : MembershipPrice.oneTime * monthsForPrice;
  }
};

export const getTotalPriceBeforeTaxWithoutDiscountLegacy = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return MembershipPriceSB.monthly * monthsForPrice;
    default: //Membership.MultiMonthSubscription:
      return MembershipPriceSB.monthly * monthsForPrice;
  }
};

export const getTotalPriceBeforeTaxWithoutDiscount = (
  subscription: Membership,
  monthsForPrice: number
) => {
  switch (subscription) {
    case Membership.MonthlySubscription:
      return MembershipPriceSB.monthly;
    default: //Membership.MultiMonthSubscription:
      return MembershipPriceSB.monthly * monthsForPrice;
  }
};

export const formatPrice = (
  amount: number,
  includeDecimals: boolean = true,
  currency: string = "USD",
  locale: string = "en-US"
): string => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  }).format(amount);
};
