import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

interface SurveyRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}
const SurveyRoute: React.FC<SurveyRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { currentUser } = useAuth();

  // user has an account and is not anonymous and on the survey page
  const shouldRedirectToHome =
    !currentUser?.isAnonymous && location.pathname.startsWith("/survey");

  const renderComponent = (props: RouteProps) => {
    if (shouldRedirectToHome) {
      return <Redirect to="/tabs/home" />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default SurveyRoute;
