import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useQuery } from "../hooks/useQuery";
import { useUserRealTime } from "../hooks/useUserRealTime";
import { CheckoutRoutes } from "../typings/enums";
import { useSurveySessionDataRealTime } from "../hooks/useSurveySessionDataRealTime";

interface CheckoutRouteProps extends RouteProps {
  component: React.ComponentType<object>;
}

const isCheckoutRoute = (pathname: string): boolean => {
  return Object.values(CheckoutRoutes).includes(pathname as CheckoutRoutes);
};

// The following routing rulles are for the following routes:
// Approval = "/approval",
// Results = "/results",
// Address = "/address",
// Payment = "/payment"
// Account = "/account"

const CheckoutRoute: React.FC<CheckoutRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const query = useQuery();
  const surveySessionId = query.get("surveySessionId") ?? "";
  const { currentUser } = useAuth();
  const user = useUserRealTime(currentUser?.uid ?? "");
  const surveySession = useSurveySessionDataRealTime(surveySessionId);
  const location = useLocation();

  // user paid but doesn't have a phone number
  const shouldRedirectToAccount =
    surveySessionId &&
    user?.paymentsService &&
    !user?.cellPhoneNumber &&
    isCheckoutRoute(location.pathname) &&
    location.pathname !== CheckoutRoutes.Account;

  // user didn't pay and there is no session
  const shouldRedirectToHome =
    isCheckoutRoute(location.pathname) &&
    !surveySessionId &&
    surveySession &&
    !surveySession?.completed;

  // user paid and has a phone number
  const shouldRedirectToAppt =
    isCheckoutRoute(location.pathname) &&
    user?.cellPhoneNumber &&
    user?.paymentsService;

  const renderComponent = (props: RouteProps) => {
    if (shouldRedirectToAccount) {
      return (
        <Redirect
          to={`${CheckoutRoutes.Account}?surveySessionId=${surveySessionId}`}
        />
      );
    }

    if (shouldRedirectToHome) {
      return <Redirect to="/tabs/home" />;
    }

    if (shouldRedirectToAppt) {
      return <Redirect to="/create-appointment/0" />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={renderComponent} />;
};

export default CheckoutRoute;
