import { useEffect } from "react";
import { isPlatform } from "@ionic/react";
import { App } from "@capacitor/app";
import localforage from "localforage";

const APP_VERSION_KEY = "app_version";

declare global {
  interface Window {
    simulateVersionChange?: () => void;
  }
}

export function useAppUpdate() {
  useEffect(() => {
    const initialize = async () => {
      try {
        if (isPlatform("hybrid")) {
          const { version } = await App.getInfo();
          const lastVersion =
            await localforage.getItem<string>(APP_VERSION_KEY);

          if (version !== lastVersion) {
            // Clear Ionic view cache
            const views = document.querySelectorAll("ion-view");
            views.forEach((view) => view.remove());
            await localforage.setItem(APP_VERSION_KEY, version);
            window.location.reload();
          }
        }
      } catch (error) {
        console.error("WebView update failed:", error);
      }
    };

    initialize();
  }, []);

  // Dev testing helper
  if (import.meta.env.DEV) {
    window.simulateVersionChange = () => {
      localforage.setItem(APP_VERSION_KEY, "old-version");
      window.location.reload();
    };
  }
}
