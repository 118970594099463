export const SMD_QUIZ_QUESTION_1 = "Quiz Question 1";
export const CHECKOUT = "Checkout";
export const PAYMENT_PAGE = "Payment Page";
export const ADDRESS_COLLECTION_PAGE = "Address Collection Page";
export const PLAN_PURCHASED = "Plan Purchased";
export const SURVEY_COMPLETE = "Survey Complete";
export const ACCOUNT_INFO = "Account Info";
export const APPOINTMENT_BOOKED = "Appointment Booked";
export const TELEMEDICINE_AND_PRESCRIPTION_AGREEMENT =
  "Telemedicine & Prescription Agreement";
export const BOOKING_INTRO = "Booking Intro";
export const SCHEDULE_APPOINTMENT_PAGE = "Schedule Appointment Page";

// local storage keys
export const LOCAL_STORAGE_AFF_ID = "affid";
export const LOCAL_STORAGE_MOON_SESSION_ID = "moonSessionId";

// event landing values
export const EVENT_LANDING_SURVEY_QUESTION = "survey-question";
export const EVENT_LANDING_POST_SURVEY_SIGNUP = "post-survey-signup";
export const USER_DISQUALIFIED = "User Disqualified";
