import { useEffect, useState } from "react";
import { SurveySession } from "../typings";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../conf/firebaseConfig";
import { genericConverter } from "../services/firestoreService";
import { addWeeksToDate, getAgeFromBirthdate } from "../utils/helpers";

export const useSurveySessionDataRealTime = (surveySessionId: string) => {
  const [surveySessionData, setSurveySessionData] =
    useState<Partial<SurveySession> | null>(null);
  useEffect(() => {
    if (!surveySessionId) {
      return;
    }

    // Reference to the Firestore document
    const docRef = doc(db, "surveySessions", surveySessionId).withConverter(
      genericConverter<Partial<SurveySession>>()
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(
      docRef,
      (document) => {
        if (document.exists()) {
          setSurveySessionData({ id: document.id, ...document.data() });
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching real-time updates: ", error);
      }
    );

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [surveySessionId]);

  return surveySessionData;
};

export const usePlanTimelineData = (surveySessionId: string) => {
  const surveySessionData = useSurveySessionDataRealTime(surveySessionId);
  const goalWeight = surveySessionData?.answers?.goal_weight ?? "0";
  const currentWeight =
    surveySessionData?.answers?.height_weight?.weight ?? "0";

  const weeks = Math.abs(Math.ceil((currentWeight - goalWeight) / 2));
  const months = Math.ceil(weeks / 4);

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  const todayDate = new Date().toLocaleDateString(undefined, options);
  const endDate = addWeeksToDate(weeks).toLocaleDateString(undefined, options);
  const midDate = addWeeksToDate(weeks / 2).toLocaleDateString(
    undefined,
    options
  );

  const formattedEndDateOptions: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  const formattedEndDate = addWeeksToDate(weeks).toLocaleDateString(
    undefined,
    formattedEndDateOptions
  );

  const monthsForPrice = Math.max(2, Math.min(months, 6));

  return {
    todayDate,
    endDate,
    midDate,
    months,
    monthsForPrice,
    goalWeight,
    currentWeight,
    formattedEndDate,
  };
};

export const useSurveySessionUserData = (
  surveySessionId: string,
  serverSideDiscount?: number
) => {
  const surveySessionData = useSurveySessionDataRealTime(surveySessionId);
  const firstName = surveySessionData?.user?.firstName || "";
  const sex = surveySessionData?.answers?.sex === "m" ? "male" : "female";
  const dateOfBirth = surveySessionData?.answers?.date_of_birth ?? "";
  const age = dateOfBirth ? getAgeFromBirthdate(dateOfBirth) : 0;
  const discountPercent = serverSideDiscount ?? 25;
  const discountCode = `${firstName.toUpperCase()}-SAVE${discountPercent}`;
  const discountPercentDQ = 30;
  const discountCodeDQ = `${firstName.toUpperCase()}CODE${discountPercentDQ}`;
  const goalWeight = surveySessionData?.answers?.goal_weight ?? "0";
  const currentWeight =
    surveySessionData?.answers?.height_weight?.weight ?? "0";
  return {
    goalWeight,
    currentWeight,
    discountPercent,
    discountCode,
    discountCodeDQ,
    discountPercentDQ,
    firstName,
    age,
    sex,
  };
};
