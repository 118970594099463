import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import * as types from "./moonService.types";
import localforage from "localforage";
import {
  LOCAL_STORAGE_AFF_ID,
  LOCAL_STORAGE_MOON_SESSION_ID,
} from "./moonService.const";
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from "../conf/firebaseConfig";

class MoonService {
  private axiosInstance: AxiosInstance;
  private readonly project: string;

  constructor(config: types.MoonServiceConfig) {
    this.project = config.project;
    this.axiosInstance = axios.create({
      baseURL: config.baseURL,
      headers: {
        accept: "application/json",
        "accept-language": "en-US,en;q=0.9",
        "Content-Type": "application/json",
      },
    });
  }

  private async request<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await this.axiosInstance.request<T>(config);
      return response.data;
    } catch (error) {
      console.error("Request failed:", error);
      throw error;
    }
  }

  async loadMasterPixelRetargeting(
    origin: string,
    referer: string
  ): Promise<types.PixelRetargetingResponse | void> {
    try {
      return this.request<types.PixelRetargetingResponse>({
        method: "POST",
        url: "/api/v1/load-master-pixel-retargeting/",
        data: { project: this.project },
        headers: { origin, referer },
      });
    } catch (e) {
      console.error("Failed to load master pixel retargeting", e);
    }
  }

  async loadMasterPixel(
    origin: string,
    referer: string,
    affid: string
  ): Promise<types.MasterPixelResponse | void> {
    try {
      return this.request<types.MasterPixelResponse>({
        method: "POST",
        url: "/api/v1/load-master-pixel/",
        data: { affid: affid, project: this.project },
        headers: { origin, referer },
      });
    } catch (e) {
      console.error("Failed to load master pixel:", e);
    }
  }

  async trackEvent(
    params: types.TrackEventParams
  ): Promise<types.TrackEventResponse | void> {
    const { origin, href } = window.location;
    try {
      const affid = await localforage.getItem<string>(LOCAL_STORAGE_AFF_ID);
      const sessionId = await localforage.getItem<string>(
        LOCAL_STORAGE_MOON_SESSION_ID
      );

      if (!sessionId) {
        return;
      }
      const trackingKey = `tracking-moon-${params.name}`;
      const eventTracked = await localforage.getItem<string>(trackingKey);
      if (eventTracked) {
        return;
      }

      // fetch remote config
      await fetchAndActivate(remoteConfig);
      const offerId = getValue(remoteConfig, "moon_offer_id").asString();

      const res = await this.request<types.TrackEventResponse>({
        method: "POST",
        url: "/api/v1/track-event/",
        data: {
          ...params,
          project: this.project,
          affid: affid,
          session_id: sessionId,
          value: 1,
          offer_id: offerId,
        },
        headers: { origin, referer: href },
      });

      // load pixel if available
      if (res.pixel) {
        loadPixels(res.pixel);
      }

      await localforage.setItem(trackingKey, true);
    } catch (e) {
      console.error("Failed to track event:", e);
    }
  }
}

export const loadPixels = (pixelStr: string) => {
  if (!pixelStr) {
    console.warn("Invalid pixel data provided");
    return;
  }

  // Parse the HTML safely using DOMParser
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(pixelStr, "text/html");

  // Create a container for the parsed elements
  const container = document.createElement("div");

  // Extract and handle scripts
  const scripts = parsedHtml.querySelectorAll("script");

  scripts.forEach((script) => {
    if (script.src) {
      // Safely handle external scripts
      const scriptEl = document.createElement("script");
      scriptEl.src = script.src;
      scriptEl.async = true;
      document.head.appendChild(scriptEl);
    } else {
      try {
        // Handle inline scripts carefully
        const inlineScriptContent = script.textContent;
        if (inlineScriptContent) {
          new Function(inlineScriptContent)(); // Avoid eval, use new Function instead
        } else {
          console.warn("Inline script content is null");
        }
      } catch (err) {
        console.warn("Error executing inline script:", err);
      }
    }
  });

  // Append non-script elements to the container
  Array.from(parsedHtml.body.childNodes).forEach((node) => {
    if (node.nodeName !== "SCRIPT") {
      container.appendChild(node.cloneNode(true));
    }
  });

  // Append the sanitized container to the DOM
  document.body.appendChild(container);

  return container; // Return the container for reference if needed
};

export default MoonService;
