import { createRoot } from "react-dom/client";
import App from "./App";
import {
  MantineProvider,
  DEFAULT_THEME,
  MantineThemeOverride,
} from "@mantine/core";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "./components/ErrorBoundaryFallback";
import { PostHogProvider } from "posthog-js/react";
import useDatadogRum from "./hooks/useDatadogRum";
import { blurple } from "./theme/colors";
import MoonService from "./services/moonService";
import { AuthProvider } from "./context/AuthContext";

const theme: MantineThemeOverride = {
  ...DEFAULT_THEME,
  colors: {
    ...DEFAULT_THEME.colors,
    blurple,
  },
  primaryColor: "blurple",
};

export const moonClient = new MoonService({
  baseURL: import.meta.env.VITE_MOON_BASE_URL,
  project: import.meta.env.VITE_MOON_PROJECT,
});

// Initialize environment variables and configurations
const environment = import.meta.env.MODE;
const appVersion = __APP_VERSION__;
const postHogOptions = {
  session_recording: {
    blockClass: "ph-no-capture",
    blockSelector: null,
    ignoreClass: "ph-ignore-input",
    maskAllInputs: false,
    maskInputOptions: { text: false, email: false, tel: false, password: true },
    recordCrossOriginIframes: true,
    maskInputFn: null,
  },
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

const container = document.getElementById("root");
const root = createRoot(container!);

const Main = () => {
  // Initialize Datadog RUM
  const datadogRum = useDatadogRum({ appVersion, environment });

  return (
    <MantineProvider theme={theme}>
      <ErrorBoundary
        fallbackRender={ErrorBoundaryFallback}
        onError={(error, errorInfo) =>
          datadogRum.addError(error, { errorInfo, source: "react" })
        }
      >
        <PostHogProvider
          apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
          options={postHogOptions}
        >
          <AuthProvider>
            <App />
          </AuthProvider>
        </PostHogProvider>
      </ErrorBoundary>
    </MantineProvider>
  );
};

root.render(<Main />);
