const WEIGHT_LOSS_CONSTANTS = {
  MIN_MONTHS: 2,
  MAX_MONTHS: 6,
  WEIGHT_LOSS_PER_MONTH: 8, // 2 lbs/week * 4 weeks
};

export const getGoalMonths = (startWeight: string, goalWeight: string) => {
  const weightLoss = parseInt(startWeight, 10) - parseInt(goalWeight, 10);
  let months = Math.ceil(
    Math.abs(weightLoss / WEIGHT_LOSS_CONSTANTS.WEIGHT_LOSS_PER_MONTH)
  );
  if (months > WEIGHT_LOSS_CONSTANTS.MAX_MONTHS)
    months = WEIGHT_LOSS_CONSTANTS.MAX_MONTHS;
  else if (months < WEIGHT_LOSS_CONSTANTS.MIN_MONTHS)
    months = WEIGHT_LOSS_CONSTANTS.MIN_MONTHS;
  return months;
};
