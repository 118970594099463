import localforage from "localforage";
import { useEffect, useState } from "react";

type UseLocalForage = [
  string | null,
  (data: string) => void,
  () => void,
  boolean,
];

export const useLocalForage = (key: string): UseLocalForage => {
  const [storage, _setStorage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        await localforage.getItem<string>(key, (err, response) => {
          if (err) {
            setIsLoading(false);
            return;
          }

          if (response) {
            _setStorage(response);
          }

          setIsLoading(false);
        });
      } catch (err) {
        setIsLoading(false);
      }
    })();
  }, [key]);

  const setStorage = (data: string) => {
    setIsLoading(true);
    localforage.setItem<string>(key, data).catch((_) => {});
    _setStorage(data);
    setIsLoading(false);
  };

  const removeItem = () => {
    setIsLoading(true);
    localforage.removeItem(key).catch((_) => {});
    _setStorage(null);
    setIsLoading(false);
  };

  return [storage, setStorage, removeItem, isLoading];
};
